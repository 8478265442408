<template>
    <section class="px-5 pt-3">
        <div class="row mx-0 mb-4">
            <div class="col-auto px-0 text-black f-500 f-20">
                Vigilantes
            </div>
            <div class="ml-auto btn-general px-3 cr-pointer d-middle br-5" @click="crearVigilante">
                Crear vigilante
            </div>
            <div class="col-3 ">
                <el-input v-model="buscar" prefix-icon="icon-buscar f-16" size="small" placeholder="Buscar" class="w-100" @input="getVigilantes" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 205px)">
            <div v-if="vigilantes.length > 0 && !cargando" class="row mx-0">
                <div v-for="(data,key) in vigilantes" :key="key" class="card-vigilante mr-4 cr-pointer mb-3" @click="editarVigilante(data.id)">
                    <div class="row mx-0 h-100 align-items-center">
                        <div class="col-12">
                            <i class="icon-id-badge text-black f-20" />
                            <span class="text-black f-16 f-500 ml-1"> {{ data.nombre }} {{ data.apellido }} </span>
                        </div>
                        <div class="col-12 d-middle">
                            <i class="icon-phone text-black f-16" />
                            <span class="text-black f-14 ml-2"> {{ data.telefono }} </span>
                            <div v-if="data.responsable == 1" class="pr-0 f-13 text-primary ml-auto">
                                Responsable
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row mx-0">
                <div v-if="!cargando" class="col-12 text-center mt-2">
                    <p class="f-14">No hay vigilantes creados</p>
                </div>
            </div>
            <div v-if="cargando" class="col-12 text-center mt-2">
                <p class="f-14">Cargando Vigilantes...</p>
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/vigilantes/vigilantes'

export default {
    data(){
        return {
            cargando: false,
            vigilantes: [],
            buscar: '',
        }
    },

    mounted(){
        this.getVigilantes()
    },

    methods:{
        async getVigilantes(){
            try {
                this.cargando = true;

                const busqueda = async() => {
                    const params = {
                        buscar: this.buscar
                    }
                    const {data} = await Service.getListarVigilantes(params);
                    this.vigilantes = data.data;
                }
                await this.delay(busqueda);
            } catch (e){
                this.errorCatch(e);
            } finally {
                this.cargando = false;
            }
        },

        crearVigilante(){
            this.$router.push({name: 'vigilantes.crear'});
        },

        editarVigilante(id){
            this.$router.push({name: 'vigilantes.editar', params: { idVigilante: id }});
        },
    }
}
</script>
<style lang="scss" scoped>
.card-vigilante{
    width: 281px;
    height: 82px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background-color: #FCFCFC;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
    }
}
</style>
import axios from 'axios'

const API = '/vigilantes-conjuntos'

const Conjuntos = {
    getListarVigilantes(params){
        return axios.get(`${API}/listar`, { params });
    },
    postVigilante(payload){
        return axios.post(`${API}/guardar`, payload);
    },
    getDetalleVigilante(idVigilante){
        return axios.get(`${API}/${idVigilante}/detalle`);
    },
    putVigilante(idVigilante, payload){
        return axios.put(`${API}/${idVigilante}/editar`, payload);
    },
    deleteVigilante(idVigilante){
        return axios.delete(`${API}/${idVigilante}/eliminar`);
    }
}

export default Conjuntos
